import React, { useEffect, useState } from "react";
import { Row, Col } from "antd";
import { Navigate, useLocation } from "react-router-dom";

import CustomProperties from "react-custom-properties";

import LoginForm from "components/forms/LoginForm";
import AuthService from "services/AuthService";
import ToastActions from "actions/ToastActions";
import ThemeActions from "actions/ThemeActions";
import LoginStore from "stores/LoginStore";
import ThemeStore from "stores/ThemeStore";

const Login: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [redirectToReferrer, setRedirectToReferrer] = useState(false);
  const [theme, setTheme] = useState<any>(ThemeStore.get());

  const location = useLocation();
  const { from } = location.state || { from: { pathname: '/' } };

  useEffect(() => {
    const listener = ThemeStore.addListener(() => {
      setTheme(ThemeStore.get());
    });
    ThemeActions.reload();
    return () => {
      listener.remove();
    }
  }, []);

  useEffect(() => {
    const listener = LoginStore.addListener(() => {
      if (LoginStore.isLoggedIn()) {
        setRedirectToReferrer(true);
      }
    });
    return () => {
      listener.remove();
    }
  }, []);

  const login = (username: string, password: string) => {
    setLoading(true);
    AuthService.login(username, password)
      .then(() => {
        setRedirectToReferrer(true);
      })
      .catch((err) => {
        const resp = JSON.parse(err.response);
        ToastActions.createToastError(resp.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  if (redirectToReferrer) {
    return <Navigate to={from} />;
  }

  return (
    <>
      <CustomProperties global properties={theme} />
      <Row
        style={{ backgroundImage: 'url("images/login-background.jpg")' }}
        align="middle"
        justify="center"
        className="login-page"
      >
        <Col xs={14} sm={8} md={6} lg={4} xl={4}>
          <img
            src="images/hw-logo.png"
            alt="logo"
            style={{ width: "100%", paddingBottom: "1rem" }}
          />
          <LoginForm
            onLogin={login}
            loading={loading}
          />
        </Col>
      </Row>
    </>
  );
}

export default Login;