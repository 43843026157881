import React, { useState, useEffect } from "react";
import { Modal } from "antd";

import ReportStore from "../../stores/ReportStore";
import ReportActions from "../../actions/ReportActions";
import IncidentStore from "../../stores/IncidentStore";
import IncidentActions from "../../actions/IncidentActions";
import ReviewStore from "../../stores/ReviewStore";
import ReviewActions from "../../actions/ReviewActions";

import DocumentList from "views/document/DocumentList";

/**
 * The modal to select user documents to print.
 */
export default function SelectUserDocumentsToPrintModal({
  user,
  visible,
  onCancel,
}) {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    Promise.all([
      ReportActions.reloadByUser(user),
      IncidentActions.reloadByUser(user),
      ReviewActions.reloadByUser(user),
    ]).finally(() => setLoading(false));
  }, [user]);

  const reports = ReportStore.getByUser(user.id);
  const incidents = IncidentStore.getByUser(user.id);
  const reviews = ReviewStore.getByUser(user.id);

  return (
    <Modal
      title="Sélectionnez les documents à imprimer"
      open={user && visible}
      onCancel={onCancel}
      footer={null}
      className="select-user-documents-to-print-modal"
    >
      <DocumentList
        loading={loading}
        reports={reports}
        incidents={incidents}
        reviews={reviews}
      />
    </Modal>
  );
}
