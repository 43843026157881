import React, { useState } from "react";
import { Checkbox, Button, Tooltip } from "antd";

import Locale from "locale/LocaleFactory";
import DocumentsActions from "actions/DocumentsActions";
import LoadingIcon from "components/LoadingIcon.jsx";

import ReportService from "services/ReportService";
import IncidentService from "services/IncidentService";
import ReviewService from "services/ReviewService";
import UserService from "services/UserService";

import "./DocumentList.scss";
import ShowDocumentModal from "./ShowDocumentModal";
import { EyeOutlined, PrinterOutlined } from "@ant-design/icons";

/**
 * The modal to select documents to print.
 */
export default function DocumentList({ loading, users, reports, incidents, reviews }) {
  const [buttonLoading, setButtonLoading] = useState(false);
  const [selectedReports, setSelectedReports] = useState([]);
  const [selectedIncidents, setSelectedIncidents] = useState([]);
  const [selectedReviews, setSelectedReviews] = useState([]);
  const [selectedCertificates, setSelectedCertificates] = useState([]);
  const [selectedSkills, setSelectedSkills] = useState([]);
  const [documentToShow, setDocumentToShow] = useState(null);

  function onSelectedChange(id, setter) {
    setter((prev) => {
      const newSelected = [...prev];
      if (newSelected.includes(id)) {
        newSelected.splice(newSelected.indexOf(id), 1);
      } else {
        newSelected.push(id);
      }
      return newSelected;
    });
  }

  function onReportsChange(id) {
    onSelectedChange(id, setSelectedReports);
  }

  function onIncidentsChange(id) {
    onSelectedChange(id, setSelectedIncidents);
  }

  function onReviewsChange(id) {
    onSelectedChange(id, setSelectedReviews);
  }

  function onCertificatesChange(id) {
    onSelectedChange(id, setSelectedCertificates);
  }

  function onSkillsChange(id) {
    onSelectedChange(id, setSelectedSkills);
  }

  const totalDocuments = reports.length + incidents.length + reviews.length + users.length;
  return (
    <div className="document-list">
      {loading && <LoadingIcon />}

      {!loading && totalDocuments === 0 && <div>Aucun document</div>}

      {reports.length > 0 && (
        <div className="__title">
          <Checkbox
            indeterminate={
              selectedReports.length > 0 &&
              selectedReports.length !== reports.length
            }
            onChange={(e) =>
              setSelectedReports(
                e.target.checked ? reports.map((i) => i.id) : []
              )
            }
            checked={selectedReports.length === reports.length}
          >
            <h3>Rapports d'activité</h3>
          </Checkbox>
        </div>
      )}
      {reports.map((report) => (
        <DocumentItem
          key={report.id}
          document={report}
          checked={selectedReports.includes(report.id)}
          onChange={onReportsChange}
          onShowClick={setDocumentToShow}
        />
      ))}

      {incidents.length > 0 && (
        <div className="__title">
          <Checkbox
            indeterminate={
              selectedIncidents.length > 0 &&
              selectedIncidents.length !== incidents.length
            }
            onChange={(e) =>
              setSelectedIncidents(
                e.target.checked ? incidents.map((i) => i.id) : []
              )
            }
            checked={selectedIncidents.length === incidents.length}
          >
            <h3>Rapports d'incident</h3>
          </Checkbox>
        </div>
      )}
      {incidents.map((incident) => (
        <DocumentItem
          key={incident.id}
          document={incident}
          checked={selectedIncidents.includes(incident.id)}
          onChange={onIncidentsChange}
          onShowClick={setDocumentToShow}
        />
      ))}

      {reviews.length > 0 && (
        <div className="__title">
          <Checkbox
            indeterminate={
              selectedReviews.length > 0 &&
              selectedReviews.length !== reviews.length
            }
            onChange={(e) =>
              setSelectedReviews(
                e.target.checked ? reviews.map((i) => i.id) : []
              )
            }
            checked={selectedReviews.length === reviews.length}
          >
            <h3>Bilans</h3>
          </Checkbox>
        </div>
      )}
      {reviews.map((review) => (
        <DocumentItem
          key={review.id}
          document={review}
          checked={selectedReviews.includes(review.id)}
          onChange={onReviewsChange}
          onShowClick={setDocumentToShow}
        />
      ))}

      {users.length > 0 && (
        <div className="__title">
          <Checkbox
            indeterminate={
              selectedCertificates.length > 0 &&
              selectedCertificates.length !== users.length
            }
            onChange={(e) =>
              setSelectedCertificates(
                e.target.checked ? users.map((i) => `${i.id}_${i.mission.id}`) : []
              )
            }
            checked={selectedCertificates.length === users.length}
          >
            <h3>Certificats de compétence</h3>
          </Checkbox>
        </div>
      )}
      {users.map((user) => {
        const id = `${user.id}_${user.mission.id}`;
        return <DocumentItem
          key={id}
          document={{
            id,
            title: `${user.firstName} ${user.lastName}`,
            documentType: 'certificate-of-competence'
          }}
          checked={selectedCertificates.includes(id)}
          onChange={onCertificatesChange}
        />;
      })}

      {users.length > 0 && (
        <div className="__title">
          <Checkbox
            indeterminate={
              selectedSkills.length > 0 &&
              selectedSkills.length !== users.length
            }
            onChange={(e) =>
              setSelectedSkills(
                e.target.checked ? users.map((i) => `${i.id}_${i.mission.id}`) : []
              )
            }
            checked={selectedSkills.length === users.length}
          >
            <h3>Grilles de compétences</h3>
          </Checkbox>
        </div>
      )}
      {users.map((user) => {
        const id = `${user.id}_${user.mission.id}`;
        return <DocumentItem
          key={id}
          document={{
            id,
            title: `${user.firstName} ${user.lastName}`,
            documentType: 'skills'
          }}
          checked={selectedSkills.includes(id)}
          onChange={onSkillsChange}
        />;
      })}

      {totalDocuments > 0 && (
        <Button
          type="primary"
          htmlType="submit"
          style={{ marginTop: "15px" }}
          className="login-form-button"
          loading={buttonLoading}
          icon={<PrinterOutlined />}
          disabled={
            selectedReports.length === 0 &&
            selectedIncidents.length === 0 &&
            selectedReviews.length === 0 &&
            selectedCertificates.length === 0 &&
            selectedSkills.length === 0
          }
          onClick={() => {
            setButtonLoading(true);
            DocumentsActions.download(
              selectedReports,
              selectedIncidents,
              selectedReviews,
              selectedCertificates,
              selectedSkills
            ).finally(() => setButtonLoading(false));
          }}
        >
          {Locale.trans("print")}
        </Button>
      )}
      {documentToShow &&
        <ShowDocumentModal
          document={documentToShow}
          visible={!!documentToShow}
          onCancel={() => setDocumentToShow(null)}
        />}
    </div>
  );
}

function downloadPDF(document) {
  if (document.documentType === "report") {
    ReportService.download(document.id);
  } else if (document.documentType === "incident") {
    IncidentService.download(document.id);
  } else if (document.documentType === "review") {
    ReviewService.download(document.id);
  } else if (document.documentType === "certificate-of-competence") {
    const sId = document.id.split('_');
    if (sId.length === 2) {
      UserService.download(sId[0], sId[1]);
    }
  } else if (document.documentType === "skills") {
    const sId = document.id.split('_');
    if (sId.length === 2) {
      UserService.downloadSkills(sId[0], sId[1]);
    }
  }
}

function DocumentItem({ document, checked, onChange, onShowClick }) {
  if (!document) {
    return null;
  }
  return (
    <div className="__document-item">
      <div className="__checkbox">
        <Checkbox
          style={{ margin: "5px" }}
          checked={checked}
          onChange={() => onChange(document.id)}
        >
          {document.title}
        </Checkbox>
      </div>
      <div className="__actions">
        <Tooltip title={Locale.trans("print")}>
          <Button
            type="primary"
            shape="circle"
            icon={<PrinterOutlined />}
            onClick={() => downloadPDF(document)}
          />
        </Tooltip>
        {onShowClick &&
          <Tooltip title={Locale.trans("view")}>
            <Button
              type="primary"
              shape="circle"
              icon={<EyeOutlined />}
              onClick={() => onShowClick(document)}
            />
          </Tooltip>}
      </div>
    </div>
  );
}
