
import ServiceBase from "../services/ServiceBase";
import BaseUrlConstants from "../constants/BaseUrlConstants";
import TokenContainer from "../services/TokenContainer";

const URL = `${BaseUrlConstants.BASE_URL}`;

class EmargementService extends ServiceBase {
  constructor() {
    super(URL);
  }

  /**
   * Get a emargement by unique identifier.
   * @param {number} emargementId The identifier of the emargement.
   * @returns {Promise} A promise to handle the request ascynchronously.
   */
  getById(emargementId) {
    return this.execute({
      url: `${BaseUrlConstants.BASE_URL}emargements/${emargementId}`,
      method: "GET"
    });
  }

  /**
   * Get emargement of a mission.
   * @param {number} missionId The identifier of the mission.
   * @returns {Promise} A promise to handle the request ascynchronously.
   */
  getByMission(missionId) {
    return this.execute({
      url: `${BaseUrlConstants.BASE_URL}missions/${missionId}/emargements`,
      method: "GET"
    });
  }

  /**
   * Get emargement of a user.
   * @param {number} userId The identifier of the user.
   * @returns {Promise} A promise to handle the request ascynchronously.
   */
  getByUser(userId) {
    return this.execute({
      url: `${BaseUrlConstants.BASE_URL}users/${userId}/emargements`,
      method: "GET"
    });
  }

  /**
   * Get all emargements.
   *
   * @returns {Promise} A promise to handle the request ascynchronously.
   */
  getAll() {
    return this.execute({
      url: `${BaseUrlConstants.BASE_URL}emargements`,
      method: "GET"
    });
  }

  /**
   * Post a new emargement to an user.
   * @param {Object} emargement The emargement to create.
   * @returns {Promise} A promise to handle the request ascynchronously.
   */
  post(emargement) {
    return this.execute({
      url: `${BaseUrlConstants.BASE_URL}emargements`,
      method: "POST",
      data: emargement
    });
  }

  /**
   * Patch an existing resource. Only the properties that are set on the patch will be updated.
   * @param {number} emargementId The identifier of the emargement.
   * @param {Object} patch The properties to update.
   * @returns {Promise} A promise to handle the request ascynchronously.
   */
  patch(emargementId, patch) {
    return this.execute({
      url: `${BaseUrlConstants.BASE_URL}emargements/${emargementId}`,
      method: "PATCH",
      data: patch
    });
  }

  /**
   * Delete an existing emargement.
   * @param {number} emargementId The identifier of the emargement.
   * @returns {Promise} A promise to handle the request ascynchronously.
   */
  remove(emargementId) {
    return this.execute({
      url: `${BaseUrlConstants.BASE_URL}emargements/${emargementId}`,
      method: "DELETE"
    });
  }

  /**
   * Download Mission's emargements in PDF format.
   * @param  {number} missionId The ID of the mission.
   * @return {[type]}            [description]
   */
  download(missionId, groupBy, period) {
    const token = TokenContainer.get().replace(/[+]/g, "%2B");
    const url = `${
      BaseUrlConstants.BASE_URL
    }missions/${missionId}/emargements.pdf`;
    const periodStr = encodeURIComponent(JSON.stringify(period));
    const groupByParam = groupBy ? "&group-by=" + groupBy : "";
    const periodParam = periodStr ? "&period=" + periodStr : "";
    window.location = `${url}?X-Auth-Token=${token}${groupByParam}${periodParam}`;
  }

  /**
   * Download user's emargements in PDF format.
   * @param  {number} userId The ID of the user.
   * @return {[type]}            [description]
   */
  downloadByIntern(userId, period) {
    const token = TokenContainer.get().replace(/[+]/g, "%2B");
    const url = `${
      BaseUrlConstants.BASE_URL
    }users/${userId}/emargements.pdf`;
    const periodStr = encodeURIComponent(JSON.stringify(period));
    const periodParam = periodStr ? "&period=" + periodStr : "";
    window.location = `${url}?X-Auth-Token=${token}${periodParam}`;
  }
}

const service = new EmargementService();
export default service;