import ServiceBase from "../services/ServiceBase";
import BaseUrlConstants from "../constants/BaseUrlConstants";

const URL = `${BaseUrlConstants.BASE_URL}`;

class MissionService extends ServiceBase {
    constructor() {
        super(URL);
    }

    /**
     * Get a mission by unique identifier.
     * @param {number} missionId The identifier of the mission.
     * @returns {Promise} A promise to handle the request ascynchronously.
     */
    getById(missionId) {
        return this.execute({
            url: `${BaseUrlConstants.BASE_URL}missions/${missionId}`,
            method: "GET"
        });
    }

    /**
     * Get missions of a user.
     * @param {number} userId The identifier of the user.
     * @returns {Promise} A promise to handle the request ascynchronously.
     */
    getByUser(userId) {
        return this.execute({
            url: `${BaseUrlConstants.BASE_URL}users/${userId}/missions`,
            method: "GET"
        });
    }

    /**
     * Get all unarchived missions.
     *
     * @returns {Promise} A promise to handle the request ascynchronously.
     */
    getAll() {
        return this.execute({
            url: `${BaseUrlConstants.BASE_URL}missions?archived=false`,
            method: "GET"
        });
    }

    /**
     * Get all archived missions.
     *
     * @returns {Promise} A promise to handle the request ascynchronously.
     */
    getAllArchived() {
        return this.execute({
            url: `${BaseUrlConstants.BASE_URL}missions?archived=true`,
            method: "GET"
        });
    }

    /**
     * Post a new mission.
     * @param {Object} mission The mission to create.
     * @returns {Promise} A promise to handle the request ascynchronously.
     */
    post(mission) {
        return this.execute({
            url: `${BaseUrlConstants.BASE_URL}missions`,
            method: 'POST',
            data: mission,
        });
    }

    /**
     * Archive or unarchive a mission.
     * @param {number} missionId The identifier of the mission to archive or unarchive.
     * @returns {Promise} A promise to handle the request ascynchronously.
     */
    archiving(missionId) {
        return this.execute({
            url: `${BaseUrlConstants.BASE_URL}missions/${missionId}/archiving`,
            method: "POST"
        });
    }

    /**
     * Patch an existing resource. Only the properties that are set on the patch will be updated.
     * @param {number} id The identifier of the report.
     * @param {Object} patch The properties to update.
     * @returns {Promise} A promise to handle the request ascynchronously.
     */
    patch(id, patch) {
        return this.execute({
            url: `${BaseUrlConstants.BASE_URL}missions/${id}`,
            method: 'PATCH',
            data: patch,
        });
    }

    /**
     * Delete an existing host mission.
     * @param {number} id The identifier of the mission.
     * @returns {Promise} A promise to handle the request ascynchronously.
     */
    remove(id) {
        return this.execute({
            url: `${BaseUrlConstants.BASE_URL}missions/${id}`,
            method: 'DELETE',
        });
    }
}

const service = new MissionService();
export default service;